import React from "react";
import { TypeAnimation } from "react-type-animation";
import {
  IconBrandLinkedin,
  IconBrandGithub,
  IconMailFilled,
  IconArrowBigDownFilled,
} from "@tabler/icons-react";

export default function Header() {
  return (
    <div className="min-h-screen grid grid-cols-1 sm:grid-cols-12 md:mt-20 lg:mt-0">
      <div className="flex flex-col justify-center mx-auto lg:ml-64 col-span-7 place-content-start text-left sm:text-left">
        <h1 className="text-text sm:text-5xl mb-4 text-4xl lg:text-6xl font-extrabold">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            Hello, I'm{" "}
          </span>
          <br></br>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "Fredrik Etsare",
              5000, // wait 1s before replacing "Mice" with "Hamsters"
              "Web developer",
              2000,
              "Software Engineer",
              2000,
            ]}
            wrapper="span"
            speed={50}
            repeat={Infinity}
          />
        </h1>
        <div className="flex gap-2"></div>
        <div className="flex gap-2 justify-center md:justify-normal">
          <a href="mailto:fredrik@etsare.se">
            <button
              type="button"
              class="text-white bg-gradient-to-r from-primary to-secondary hover:scale-105 transition-transform duration-300 ease font-medium rounded-lg text-sm px-6 py-2.5 text-center me-2 mb-2"
            >
              Hire me
            </button>
          </a>
          <a href="#projects">
            <button
              type="button"
              class="flex gap-2 text-white bg-gradient-to-r from-primary to-secondary hover:scale-105 transition-transform duration-300 ease font-medium rounded-lg text-sm px-6 py-2.5 text-center me-2 mb-2"
            >
              View Projects <IconArrowBigDownFilled height={20} width={20} />
            </button>
          </a>
        </div>
        <div className="flex space-x-6 items-center mt-2">
          <a
            href="https://www.linkedin.com/in/fredrik-etsare-20a535255/"
            className="text-primary transition-all duration-300 hover:text-pink-500"
            aria-label="LinkedIn Profile"
          >
            <IconBrandLinkedin size={30} />
          </a>
          <a
            href="https://github.com/Mammamu4"
            className="text-primary transition-all duration-300 hover:text-pink-500"
            aria-label="GitHub Profile"
          >
            <IconBrandGithub size={30} />
          </a>
          <a
            href="mailto:fredrik@etsare.se"
            className="text-primary transition-all duration-300 hover:text-pink-500"
            aria-label="Email Contact"
          >
            <IconMailFilled size={30} />
          </a>
        </div>
      </div>
      <div className="col-span-5 place-self-center mt-10 lg:mt-20">
        <div className="rounded-full bg-[#181818] w-[250px] h-[250px] lg:w-[400px] lg:h-[400px] relative">
          <img
            src="/images/hero-image.png"
            alt="hero"
            className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/3 left-1/2 rounded-full"
            width={400}
            height={400}
          />
        </div>
      </div>
    </div>
  );
}
