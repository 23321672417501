import React, { useState } from "react";
import projectData from "../assets/projects.json";
import DevIcons from "../components/DevIcons";
const Work = () => {
  const projects = projectData;
  const [hoveredProject, setHoveredProject] = useState(null);
  return (
    <section className="container mx-auto px-4 py-16 fade-in-from-top">
      <DevIcons />
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {projects.map((project) => (
          <div
            key={project.id}
            className={`relative rounded-xl overflow-hidden shadow-md transition-all duration-300 ${
              project.id % 5 === 0 || project.id % 8 === 0
                ? "col-span-2 row-span-2 h-64 md:h-80"
                : "h-48"
            } ${hoveredProject === project.id ? "scale-[1.02]" : ""} ${
              project.id % 4 >= 2 ? "fade-in-from-right" : "fade-in-from-left"
            }`}
            onMouseEnter={() => setHoveredProject(project.id)}
            onMouseLeave={() => setHoveredProject(null)}
            onClick={() => console.log(`Navigate to project ${project.id}`)}
          >
            <div className="absolute inset-0 w-full h-full">
              {project.video ? (
                <video
                  src={project.video}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-cover"
                />
              ) : (
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover"
                />
              )}
            </div>

            <div className="absolute bottom-0 left-0 p-4 w-full">
              <div
                className={`mb-2 transition-opacity duration-300 ${
                  hoveredProject === project.id ? "opacity-100" : "opacity-0"
                }`}
              >
                <a
                  href={project.link}
                  className="text-xs text-white bg-black/50 px-3 py-1 mb-2 rounded-full backdrop-blur-sm transition-colors"
                >
                  View Project →
                </a>
              </div>
              <span
                className={`inline-block px-2 py-1 rounded-full text-xs font-bold mb-2 bg-blue-200 text-blue-800`}
              >
                {project.category}
              </span>
              <br />
              <span className="text-white bg-pink-500 px-2 py-1 rounded-full font-bold text-md">
                {project.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Work;
