import Header from "./sections/Header";
import Work from "./sections/Work";
import Footer from "./sections/Footer";
import { Helmet } from "react-helmet";

function App() {
  
  return (
    <div className="bg-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fredrik Etsare</title>
        <meta
          name="description"
          content="Fredrik Etsare Software Enginner Portfolio"
        />
        <meta
          name="keywords"
          content="Fredrik Etsare, Fredrik, Etsare, Portfolio, Projects, Software Engineer, Web Developer"
        ></meta>
      </Helmet>
      <div id="home">
        <Header />
      </div>
      <div id="projects">
        <Work />
      </div>
    </div>
  );
}

export default App;
