import React, { useEffect, useState } from 'react';

const DevIcons = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  const icons = [
    { name: "react", color: "text-blue-500" },
    { name: "javascript", color: "text-yellow-500" },
    { name: "nodejs", color: "text-green-500" },
    { name: "typescript", color: "text-blue-700" },
    { name: "html5", color: "text-orange-500" },
    { name: "css3", color: "text-blue-500" },
    { name: "java", color: "text-blue-500" },
    { name: "spring", color: "text-blue-500" },
    { name: "docker", color: "text-sky-600" },
    { name: "git", color: "text-orange-600" },
    { name: "github", color: "text-gray-800" },
    { name: "npm", color: "text-red-600" },
    { name: "amazonwebservices", color: "text-orange-400" },
    { name: "tailwindcss", color: "text-cyan-500" },
    { name: "gradle", color: "text-cyan-500" },
    { name: "figma", color: "text-cyan-500" },
    { name: "linux", color: "text-cyan-500" },
  ];
  
  // Duplicate the icons array to create a seamless loop
  const duplicatedIcons = [...icons, ...icons, ...icons];

  useEffect(() => {
    // Animation function to create infinite scrolling effect
    const animateScroll = () => {
      setScrollPosition(prevPosition => {
        // Reset position when we've scrolled the width of our original icons set
        if (prevPosition >= icons.length * 64) {
          return 0;
        }
        return prevPosition + 0.5; // Adjust speed by changing this value
      });
    };

    // Set up interval for smooth animation
    const animationInterval = setInterval(animateScroll, 30);
    
    return () => clearInterval(animationInterval);
  }, [icons.length]);

  // Adding DevIcon CSS in head
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/devicon/2.15.1/devicon.min.css';
    document.head.appendChild(link);
    
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="w-full mx-auto my-4 h-16 relative overflow-hidden bg-transparent rounded-lg">
      {/* Left fade gradient */}
      <div className="absolute left-0 top-0 h-full w-16 bg-gradient-to-r from-transparent via-transparent to-transparent z-10"></div>
      
      {/* Right fade gradient */}
      <div className="absolute right-0 top-0 h-full w-16 bg-gradient-to-l from-transparent via-transparent to-transparent z-10"></div>
      
      {/* Scrolling container */}
      <div 
        className="flex items-center h-full"
        style={{ transform: `translateX(-${scrollPosition}px)` }}
      >
        {duplicatedIcons.map((item, index) => (
          <div 
            key={index} 
            className="flex flex-col items-center justify-center mx-3"
          >
            <i 
              className={`devicon-${item.name}-plain colored text-3xl transition-all hover:scale-125`}
            ></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevIcons;